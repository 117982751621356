"use client";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { AnimatePresence, motion, Variants } from "framer-motion";
import Link from "next/link";
import { useEffect, useState } from "react";
import * as SimpleIcons from 'simple-icons';

const fadeIn: Variants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 }
};

const fadeInTransition = {
    duration: 0.6
};

const staggerChildren = {
    animate: {
        transition: {
            staggerChildren: 0.1
        }
    }
};

const cardClasses = "shadow-xl hover:shadow-2xl transition-all duration-300 bg-white rounded-2xl overflow-hidden border-t-4 h-full relative";
const cardHeaderClasses = "text-white";
const cardTitleClasses = "text-3xl";
const cardDescriptionClasses = "text-slate-200";
const cardContentClasses = "p-6 pb-20";
const cardTextClasses = "mb-6 text-lg text-slate-700";
const buttonClasses = "w-[calc(100%-3rem)] text-lg py-6 absolute bottom-6 left-6";

const whyChooseReadryItems = [
    "Unlimited email to eBook conversions",
    "Daily curated content for your e-reader",
    "Seamless Kindle and Google Drive integration",
    "Affordable subscription plans",
    "Customizable content from your favorite sources",
    "User-friendly content management",
    "Regular feature updates and improvements"
];

const deviceCompatibility = [
    { name: "Kindle", apps: ["Kindle app"] },
    { name: "Boox", apps: ["Kindle app", "Google Drive"] },
    { name: "Supernote", apps: ["Kindle app", "Google Drive"] },
    { name: "reMarkable", apps: ["Google Drive"] },
    { name: "Kobo", apps: ["Google Drive"] },
];

const compatibleNewsletters = [
    { name: 'Substack', icon: SimpleIcons.siSubstack },
    { name: 'Ghost', icon: SimpleIcons.siGhost },
    { name: 'Your Boss\' email', icon: SimpleIcons.siGmail },
    { name: 'Mailchimp', icon: SimpleIcons.siMailchimp },
    { name: 'Medium', icon: SimpleIcons.siMedium },
    { name: 'Any other', icon: SimpleIcons.siMinutemailer },
];

const phrases = [
    "Your Newsletters",
    "Latest News",
    "Best of reddit",
];

export default function AnimatedHome() {
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <div className="container mx-auto px-4 py-8 sm:py-16">
                <motion.header
                    className="text-center mb-20"
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    transition={fadeInTransition}
                >
                    <h1 className="text-4xl sm:text-7xl font-extrabold mb-4 sm:mb-6 text-slate-900">
                        <AnimatePresence mode="wait">
                            <motion.span
                                key={currentPhraseIndex}
                                initial={{ opacity: 0, y: 50, scale: 0.9 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: -50, scale: 0.9 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 100,
                                    damping: 12,
                                    duration: 0.5
                                }}
                                className="inline-block"
                            >
                                {phrases[currentPhraseIndex]}
                            </motion.span>
                        </AnimatePresence>
                        <br /> <span className="text-purple-600">On Your E-Reader</span>
                    </h1>
                    <p className="text-xl sm:text-2xl text-slate-600 mb-6 sm:mb-10">Readry: Free newsletter to e-reader delivery + premium curated content</p>
                    <div className="flex flex-wrap justify-center items-center gap-4 mb-8">
                        {compatibleNewsletters.map((newsletter, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <svg
                                    role="img"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8 sm:w-12 sm:h-12 mb-2 fill-current text-gray-600"
                                >
                                    <title>{newsletter.name}</title>
                                    <path d={newsletter.icon.path} />
                                </svg>
                                <span className="text-xs text-gray-600">{newsletter.name}</span>
                            </div>
                        ))}
                    </div>
                    <Button size="lg" variant="outline" asChild className="bg-purple-600 text-white hover:bg-purple-700 transition-colors text-lg px-8 py-6">
                        <Link href="/sign-in">Start Reading for Free</Link>
                    </Button>
                    <p className="mt-4 text-sm text-slate-600">No credit card required for basic features.</p>
                </motion.header>

                <motion.div
                    className="grid md:grid-cols-2 gap-12 mb-20"
                    variants={staggerChildren as any}
                    initial="initial"
                    animate="animate"
                >
                    <motion.div variants={fadeIn} transition={fadeInTransition}>
                        <Card className={cn(cardClasses, "border-purple-600")}>
                            <CardHeader className={cn(cardHeaderClasses, "bg-purple-700")}>
                                <CardTitle className={cardTitleClasses}>Free Newsletter Delivery</CardTitle>
                                <CardDescription className={cardDescriptionClasses}>From inbox to e-reader, effortlessly</CardDescription>
                            </CardHeader>
                            <CardContent className={cardContentClasses}>
                                <ul className="space-y-2 mb-6">
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        No credit card required
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        Unlimited newsletter deliveries
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        Works with any e-readers with kindle app
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        Table of contents and QR codes for all links so you can dive in using your smartphone.
                                    </li>
                                </ul>
                                <Button asChild className={cn(buttonClasses, "bg-purple-600 hover:bg-purple-700")} aria-label="Try Free Newsletter Delivery">
                                    <Link href="/sign-in">Start Reading for Free</Link>
                                </Button>
                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div variants={fadeIn} transition={fadeInTransition}>
                        <Card className={cn(cardClasses, "border-blue-600")}>
                            <CardHeader className={cn(cardHeaderClasses, "bg-blue-700")}>
                                <CardTitle className={cardTitleClasses}>Premium Curated Content</CardTitle>
                                <CardDescription className={cardDescriptionClasses}>Your personalized digital newspaper</CardDescription>
                            </CardHeader>
                            <CardContent className={cardContentClasses}>
                                <ul className="space-y-2 mb-6">
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        Everything in the free plan +
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        News from top sites. (CNBC, Yahoo News, TechCrunch, etc.).
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                                        Google Drive integration for devices without kindle app
                                    </li>
                                </ul>
                                <div className="mb-6 space-y-2 text-lg text-slate-700">
                                    <p><span className="font-semibold">Monthly:</span> $4.99</p>
                                    <p><span className="font-semibold">Yearly:</span> $49.99 <span className="text-green-600 font-semibold">(Save 17%!)</span></p>
                                </div>
                                <Button asChild className={cn(buttonClasses, "bg-blue-600 hover:bg-blue-700")} aria-label="Subscribe to Premium Content">
                                    <Link href="/sign-in">Try Premium for Free</Link>
                                </Button>
                            </CardContent>
                        </Card>
                    </motion.div>
                </motion.div>

                <motion.section
                    className="mb-20 p-6 sm:p-10 rounded-3xl shadow-xl bg-white"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                    transition={fadeInTransition}
                >
                    <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-gray-800 text-center">Compatible with Your Favorite E-Readers</h2>
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 sm:gap-6">
                        {deviceCompatibility.map((device, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-4 rounded-lg shadow-md text-center flex flex-col h-full"
                                variants={fadeIn}
                            >
                                <h3 className="text-xl font-semibold mb-2">{device.name}</h3>
                                <ul className="text-sm text-gray-600 grow">
                                    {device.apps.map((app, appIndex) => (
                                        <li key={appIndex} className="mb-1">{app}</li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </div>
                </motion.section>

                <motion.section
                    className="mb-20 p-10 rounded-3xl shadow-xl bg-white/80 backdrop-blur-md"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                    transition={fadeInTransition}
                >
                    <h2 className="text-4xl font-bold mb-8 text-gray-800 text-center">Why Choose Readry?</h2>
                    <motion.ul
                        className="grid md:grid-cols-2 gap-6"
                        variants={staggerChildren}
                        initial="initial"
                        animate="animate"
                    >
                        {whyChooseReadryItems.map((item, index) => (
                            <motion.li key={index} className="flex items-center text-lg" variants={fadeIn}>
                                <svg className="w-8 h-8 mr-3 text-green-500 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <span>{item}</span>
                            </motion.li>
                        ))}
                    </motion.ul>
                </motion.section>

                <motion.div
                    className="text-center mb-20"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                    transition={fadeInTransition}
                >
                    <Button asChild size="lg" className="px-12 py-6 text-xl bg-linear-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                        <Link href="/sign-in">Start Reading with Readry</Link>
                    </Button>
                    <p className="mt-4 text-sm text-slate-600">Free newsletter delivery. No credit card needed to start.</p>
                </motion.div>
            </div>
        </div>
    );
}
